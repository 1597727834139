import { Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { DAYMODE, FILTEREVENTS, GlobalViewConstant, HOURDURATION, WORKFLOWEVENT } from '../shared/constants/global-view-constant';
import { EventStatusItems, WorkFlow, WorkFlowItems } from '../shared/models/global-view';
import { GlobalEventComponent } from './global-event/global-event.component';
import { GlobalChartsComponent } from './global-charts/global-charts.component';
import { GlobalViewService } from '../shared/services/global-view.service';
import { Subject, catchError, of, switchMap, takeUntil, tap } from 'rxjs';
import { MessageService, SlbSeverity } from '@slb-dls/angular-material/notification';
import { CameraProfileService } from '../shared/services/camera-profile.service';
import { EXCLUDED } from '../shared/constants/camera-profile-constant';
import { DashboardService } from '../shared/services/dashboard.service';
import { countryList, riskStatus, riskStatusWorkflow } from '../shared/models/dashabordEventDetails';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
@Component({
  selector: 'app-global-view',
  templateUrl: './global-view.component.html',
  styleUrls: ['./global-view.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class GlobalViewComponent implements OnInit, OnDestroy {
  @ViewChild(GlobalEventComponent) globalEvent: GlobalEventComponent;
  @ViewChild(GlobalChartsComponent) globalChart: GlobalChartsComponent;
  public workFlowItems = GlobalViewConstant.workFlowItems;
  public eventStatusItems = GlobalViewConstant.EventStatusItems;
  public customOption = GlobalViewConstant.customOption;
  public dateRange = GlobalViewConstant.dateRange;
  public dateRangeCalendar: string | null;
  public selectedDateRange: string | null;
  public isCustomCalendarDisabled = false;
  public startDate: Date;
  public endDate: Date;
  public selectedOption: string | null;
  public siteListItem: EventStatusItems[];
  public equipmentListItem: EventStatusItems[];
  public countryList: EventStatusItems[];
  public selectedWorkFlow: string[];
  public selectedWorkFlowId: string[] = [WORKFLOWEVENT.ALL];
  public selectedEventStatus: string[] = [WORKFLOWEVENT.ALL];
  public calendarPlaceholder = 'Select date';
  public errorMessage = '';
  public selectedRiskStatus: string[] = [WORKFLOWEVENT.ALL];
  public selectedSite: string[] = [WORKFLOWEVENT.ALL];
  public selectedCountry: string[] = [WORKFLOWEVENT.ALL];
  public filterValueChange: boolean;
  public currentFilterSelection: { [key: string]: string[] };
  public previousFilterSelection: { [key: string]: string[] };
  public riskData: riskStatus[];
  public riskDataList: EventStatusItems[];
  public selectedFilterOptions: { [key: string]: EventStatusItems[] } = {};
  public todayDate: Date;
  public formattedCalendar: string;
  public calendarEvent: Event;
  public getEnableCalendar = true;
  public isRequired: boolean;
  public endDateError: boolean;
  public equalDateError: boolean;
  public currentDateError: boolean;
  public custStartDate: Date | null;
  public custEndDate: Date | null;
  public isDefault = true;
  public formGroup: FormGroup;

  private destroyed = new Subject();
  private SECONDS_IN_HOUR = HOURDURATION.SECONDS_IN_HOUR;
  private HOUR = HOURDURATION.HOUR;
  private DAY = HOURDURATION.DAY;
  private WEEK = HOURDURATION.WEEK;
  private MONTH = HOURDURATION.MONTH;
  private TWODAY = HOURDURATION.TWODAY;
  private YEAR = HOURDURATION.YEAR;
  private prevSelection: string | null;
  private init: boolean;
  private dateInit: boolean;
  private fromClear = false;
  private workflowResponse: WorkFlow[];
  private riskDataWorkflowList: riskStatusWorkflow[] = [];

  constructor(
    private formBuilder: FormBuilder,
    private globalViewService: GlobalViewService,
    private messageService: MessageService,
    private cameraProfileService: CameraProfileService,
    private dashboardService: DashboardService
  ) {}

  ngOnInit(): void {
    this.getOnLoad();
    this.formGroup.get('startDate')?.disable();
    this.formGroup.get('endDate')?.disable();
    this.globalViewService
      .getWorkFlowId()
      .pipe(
        catchError(() => {
          this.resetLoader();
          this.messageService.add({
            severity: SlbSeverity.Error,
            summary: GlobalViewConstant.globalWorkFlowAPIError,
            closable: true,
            sticky: true,
          });

          return of<WorkFlow[]>({} as WorkFlow[]);
        }),
        tap((data: WorkFlow[]) => {
          this.workflowResponse = data;
          data.map(item => {
            this.workFlowItems?.map(workflow => {
              if (workflow.name.toLowerCase().split(' ').join('').includes(item.name.toLowerCase().split(' ').join(''))) {
                workflow.value = item.id;
              }
            });
          });
        }),
        switchMap(() =>
          this.dashboardService.getCountryData().pipe(
            tap((countryData: countryList) => {
              this.checkAdmin(countryData);
            }),
            catchError(() => {
              this.resetLoader();
              this.messageService.add({
                severity: SlbSeverity.Error,
                summary: GlobalViewConstant.GLOBALCOUNTRYERROR,
                closable: true,
                sticky: true,
              });

              return of<countryList[]>({} as countryList[]);
            }),
            takeUntil(this.destroyed)
          )
        ),
        switchMap(() =>
          this.globalViewService.getRiskList().pipe(
            tap((riskData: riskStatus[]) => {
              this.riskData = riskData;
              this.createRiskStatusWorkflowList(this.riskData);
              if (Object.keys(this.selectedFilterOptions).length === 0) {
                this.setRiskDataList(this.riskData);
              }
              this.setSessionData();
            }),
            catchError(() => {
              this.resetLoader();
              this.messageService.add({
                severity: SlbSeverity.Error,
                summary: GlobalViewConstant.GLOBALRISKERROR,
                closable: true,
                sticky: true,
              });

              return of<string[]>({} as string[]);
            })
          )
        ),
        takeUntil(this.destroyed)
      )
      .subscribe();
  }

  public getOnLoad(): void {
    this.formGroup = this.formBuilder.group({
      startDate: [this.custStartDate, [this.startDateValidator.bind(this)]],
      endDate: [null, [this.endDateValidator.bind(this)]],
    });
    this.todayDate = new Date();
    this.init = true;
    this.filterValueChange = false;
    this.formGroup.get('startDate')?.disable();
    this.formGroup.get('endDate')?.disable();
    this.getEnableCalendar = false;
    this.dateInit = true;
  }

  public validateCalendar(): void {
    this.todayDate = new Date();
    this.endDateError = false;
    this.isRequired = false;
    this.currentDateError = false;
    this.equalDateError = false;

    if (this.custStartDate && this.custEndDate && this.custStartDate?.getTime() < this.custEndDate?.getTime()) {
      this.startDate = this.custStartDate;
      this.endDate = this.custEndDate;
      this.endDateError = false;
    } else if (this.custStartDate && this.custEndDate && this.custStartDate?.getTime() >= this.custEndDate?.getTime()) {
      this.endDateError = true;
    } else if (
      (this.custStartDate && this.custStartDate?.getTime() > new Date().getTime()) ||
      (this.custEndDate && this.custEndDate?.getTime() > new Date().getTime())
    ) {
      this.currentDateError = true;
    } else {
      this.endDateError = false;
    }
    this.disableApply();
  }

  public onCustom(): void {
    this.todayDate = new Date();
    this.formGroup.get('startDate')?.enable();
    this.formGroup.get('endDate')?.enable();
    this.getEnableCalendar = true;
    this.filterValueChange = false;
    this.isCustomCalendarDisabled = this.selectedOption === DAYMODE.CUSTOM ? false : true;
    this.selectedDateRange = null;
  }

  public onSwitchDays(event: string): void {
    if (!this.dateInit && !this.init && !this.selectedOption) {
      this.filterValueChange = true;
    } else {
      this.dateInit = false;
    }
    this.selectedDateRange = event;

    this.isCustomCalendarDisabled = this.selectedOption === DAYMODE.CUSTOM ? false : true;
    if (event) {
      this.selectedOption = null;
      this.custStartDate = null;
      this.custEndDate = null;
      this.getEnableCalendar = false;
      this.formGroup.get('startDate')?.disable();
      this.formGroup.get('endDate')?.disable();
      this.generateDate(event);
    }
    this.disableApply();
  }

  public filter(name: string | undefined, checked: boolean, workItem: EventStatusItems[], filterItem: string): void {
    this.filterValueChange = true;
    if (name?.toLowerCase() === WORKFLOWEVENT.ALL && checked) {
      workItem.forEach(item => (item.checked = true));
      this.isAllCheckBoxSelected(workItem, filterItem);
    } else if (name?.toLowerCase() === WORKFLOWEVENT.ALL && !checked) {
      workItem.forEach(item => (item.checked = false));
      this.isAllCheckBxSelectforAllUncheck(workItem);
      this.getSelectedStatus(workItem, filterItem);
    } else if (name !== WORKFLOWEVENT.ALL_LABEL) {
      workItem[0].checked = false;
      this.isAllCheckBoxSelected(workItem, filterItem);
    }

    if (filterItem === 'country') {
      this.getSiteData(this.selectedCountry);
    } else if (filterItem === 'workflow') {
      if (!this.selectedWorkFlowId.includes(WORKFLOWEVENT.ALL)) {
        const riskData = this.riskData.filter(item => this.selectedWorkFlowId.includes(item.workflowId));
        this.setRiskDataList(riskData);
      } else {
        this.selectedRiskStatus = [WORKFLOWEVENT.ALL];
        this.setRiskDataList(this.riskData);
      }
    }
    this.currentFilterSelection = this.createFilterArray();
    this.disableApply();
  }

  public setCheckBoxSelection(selectedFilterOptions: { [key: string]: EventStatusItems[] }): void {
    this.selectedWorkFlowId = this.getCheckedWorkflow(selectedFilterOptions.workFlow);
    this.selectedEventStatus = this.getCheckedWorkflow(selectedFilterOptions.reviewCategory);
    this.selectedRiskStatus = this.getCheckedWorkflow(selectedFilterOptions.riskCategories);
    this.selectedCountry = this.getCheckedWorkflow(selectedFilterOptions.countryName);
    this.selectedSite = this.getCheckedWorkflow(selectedFilterOptions.site);
  }

  public setWfCheckBoxSelection(selectedFilterOptions: { [key: string]: EventStatusItems[] }): void {
    this.workFlowItems = selectedFilterOptions.workFlow;
    this.eventStatusItems = selectedFilterOptions.reviewCategory;
    this.riskDataList = selectedFilterOptions.riskCategories;
    this.countryList = selectedFilterOptions.countryName;
    this.siteListItem = selectedFilterOptions.site;
  }

  public getCheckedWorkflow(workflow: EventStatusItems[]): string[] {
    const workFlowArr: string[] = [];
    workflow?.forEach(wf => {
      if (wf.checked) {
        workFlowArr.push(wf.value);
      }
    });
    const checkAll = workFlowArr?.find((swf: string) => swf?.toLowerCase() === WORKFLOWEVENT.ALL) ? true : false;
    if (checkAll) {
      const workFlowArrAll: string[] = [];
      workFlowArrAll.push(WORKFLOWEVENT.ALL);

      return workFlowArrAll;
    }

    return workFlowArr;
  }

  public setSessionData(): void {
    this.selectedFilterOptions = JSON.parse(sessionStorage.getItem('analyticsSelection') ?? '{}');
    const dateRange = sessionStorage.getItem('dateRange');
    if (dateRange && dateRange !== DAYMODE.CUSTOM) {
      this.generateDate(dateRange);
      this.prevSelection = dateRange;
      this.selectedDateRange = dateRange;
    } else if (dateRange && dateRange === DAYMODE.CUSTOM) {
      this.selectedOption = DAYMODE.CUSTOM;
      this.selectedDateRange = null;
      this.prevSelection = null;
      const startDate = sessionStorage.getItem('customStartDate');
      this.custStartDate = startDate ? new Date(startDate) : this.startDate;
      this.startDate = startDate ? new Date(startDate) : this.startDate;
      const endDate = sessionStorage.getItem('customEndDate');
      this.custEndDate = endDate ? new Date(endDate) : this.endDate;
      this.endDate = endDate ? new Date(endDate) : this.endDate;
      this.globalChart.setChartTimeline(this.startDate, this.endDate);
    } else {
      this.prevSelection = DAYMODE.MONTH;
      this.selectedDateRange = DAYMODE.MONTH;
      this.selectedOption = null;
      this.generateDate(this.selectedDateRange);
    }
    if (Object.keys(this.selectedFilterOptions).length !== 0) {
      this.setCheckBoxSelection(this.selectedFilterOptions);
      this.currentFilterSelection = this.createFilterArray();
    } else {
      this.currentFilterSelection = this.createFilterArray();
    }
    this.selectedWorkflowList(this.currentFilterSelection.workFlow, this.currentFilterSelection.riskCategories);
    this.disableClear();
    this.globalEvent.setFirstInitialization(this.startDate, this.endDate, this.currentFilterSelection);
    this.globalChart.setFirstInitialization(this.startDate, this.endDate, this.currentFilterSelection);
  }

  public onClear(): void {
    this.dateInit = true;
    this.filterValueChange = false;
    this.prevSelection = DAYMODE.MONTH;
    this.fromClear = true;
    this.setRiskDataList(this.riskData);
    this.getSiteData([WORKFLOWEVENT.ALL_LABEL]);
    this.initData();
    this.currentFilterSelection = this.previousFilterSelection;
    this.cameraProfileService.getEventData(false);
    this.cameraProfileService.resetAnalyticsSelection();
    this.globalChart.selectedWorkFlowList([WORKFLOWEVENT.ALL], [WORKFLOWEVENT.ALL]);
    this.disableApply();
  }

  public apply(): void {
    this.selectedFilterOptions = {};
    if (!this.selectedOption) {
      this.globalChart.setChartTimeline(this.startDate, this.endDate);
      sessionStorage.removeItem('customStartDate');
      sessionStorage.removeItem('customEndDate');
    } else {
      sessionStorage.setItem('customStartDate', this.startDate.toISOString());
      sessionStorage.setItem('customEndDate', this.endDate.toISOString());
      this.globalChart.setChartTimeline(this.startDate, this.endDate);
      sessionStorage.setItem('dateRange', this.selectedOption);
    }
    this.globalChart.switchDays(this.startDate, this.endDate);
    this.globalChart.workFlowEventStatusChange(this.createFilterArray());
    this.globalEvent.switchDays(this.startDate, this.endDate);
    this.globalEvent.workFlowEventStatusChange(this.createFilterArray());
    this.cameraProfileService.getEventData(false);
    this.prevSelection = this.selectedDateRange;
    this.previousFilterSelection = this.currentFilterSelection;
    this.selectedWorkflowList(this.currentFilterSelection.workFlow, this.currentFilterSelection.riskCategories);
    if (this.selectedDateRange) {
      sessionStorage.setItem('dateRange', this.selectedDateRange);
    }
    sessionStorage.setItem('analyticsSelection', JSON.stringify(this.createFilterSelection()));
    this.filterValueChange = false;
  }

  public createRiskStatusWorkflowList(riskData: riskStatus[]): void {
    riskData.forEach(rd => {
      if (this.workflowResponse.find(wf => wf.id === rd.workflowId)?.name) {
        this.riskDataWorkflowList.push({
          riskCategory: rd.riskCategory,
          workflow: this.workflowResponse.find(wf => wf.id === rd.workflowId)?.name,
          value: 0,
        });
      }
    });
    this.globalChart.riskDataListWorkflow(this.riskDataWorkflowList);
  }

  public selectedWorkflowList(workflow: string[], riskCategories: string[]): void {
    if (workflow[0] === WORKFLOWEVENT.ALL && riskCategories[0] === WORKFLOWEVENT.ALL) {
      this.globalChart.selectedWorkFlowList([WORKFLOWEVENT.ALL], [WORKFLOWEVENT.ALL]);
    } else if (workflow[0] === WORKFLOWEVENT.ALL && riskCategories[0] !== WORKFLOWEVENT.ALL) {
      this.globalChart.selectedWorkFlowList([WORKFLOWEVENT.ALL], riskCategories);
    } else {
      const workflowArr: (string | undefined)[] = [];
      workflow.forEach(wf => {
        if (this.workflowResponse.find(wfr => wfr.id === wf)?.name) {
          workflowArr.push(this.workflowResponse.find(wfr => wfr.id === wf)?.name);
        }
      });
      this.globalChart.selectedWorkFlowList(workflowArr, riskCategories);
    }
  }
  ngOnDestroy(): void {
    this.destroyed.next(true);
    this.destroyed.complete();
  }

  private startDateValidator(group: FormControl): { [key: string]: any } | null {
    if (this.formGroup) {
      const start = group as FormControl;
      const endForm = this.formGroup.get('endDate') as FormControl;
      const end = this.formGroup.value.endDate;

      if (start && end && start.value >= end) {
        return { dateRangeInvalid: true };
      } else {
        if (endForm.hasError('dateRangeInvalid')) {
          this.formGroup.get('endDate')?.setErrors(null);
          this.formGroup.updateValueAndValidity();
        }
      }
    }

    return null;
  }

  private endDateValidator(group: FormControl): { [key: string]: any } | null {
    if (this.formGroup) {
      const startForm = this.formGroup.get('startDate') as FormControl;
      const start = startForm.value;
      const end = group as FormControl;

      if (start && end && start >= end.value) {
        return { dateRangeInvalid: true };
      } else {
        if (startForm.hasError('dateRangeInvalid')) {
          this.formGroup.get('startDate')?.setErrors(null);
          this.formGroup.updateValueAndValidity();
        }
      }
    }

    return null;
  }

  private getSiteData(countries: string[]): void {
    if (countries.length) {
      this.globalViewService
        .getSiteList(countries)
        .pipe(
          tap((siteList: WorkFlow[]) => {
            this.siteListItem = this.createFilterData(siteList);
            if (Object.keys(this.selectedFilterOptions).length !== 0 && this.init) {
              this.selectedSite = this.getCheckedWorkflow(this.selectedFilterOptions.site);
            } else {
              this.selectedSite = [WORKFLOWEVENT.ALL];
              this.currentFilterSelection = this.createFilterArray();
            }
            if (this.init) {
              this.initData();
              this.init = false;
            }
            this.disableApply();
          }),
          catchError(() => {
            this.resetLoader();
            this.messageService.add({
              severity: SlbSeverity.Error,
              summary: GlobalViewConstant.GLOBALSITEERROR,
              closable: true,
              sticky: true,
            });

            return of<WorkFlow[]>({} as WorkFlow[]);
          })
        )
        .subscribe();
    } else {
      this.siteListItem = [];
      this.selectedSite = [];
    }
  }

  private generateDate(dayMode: string): void {
    switch (dayMode) {
      case DAYMODE.HOUR:
        this.getDate(this.HOUR);
        break;
      case DAYMODE.DAY:
        this.getDate(this.DAY);
        break;
      case DAYMODE.TWODAY:
        this.getDate(this.TWODAY);
        break;
      case DAYMODE.WEEK:
        this.getDate(this.WEEK);
        break;
      case DAYMODE.MONTH:
        this.getDate(this.MONTH);
        break;
      case DAYMODE.YEAR:
        this.getDate(this.YEAR);
        break;
    }
  }

  private getDate(hour: number): void {
    this.endDate = new Date();
    this.startDate = new Date();
    if (hour !== 0) {
      if (hour === this.TWODAY) {
        this.startDate.setDate(this.startDate.getDate() - 1);
        this.startDate.setHours(0, 0, 0, 0);
      } else if (hour === this.WEEK) {
        this.startDate.setDate(this.startDate.getDate() - 6);
        this.startDate.setHours(0, 0, 0, 0);
      } else if (hour === this.MONTH) {
        this.startDate.setDate(this.startDate.getDate() - 29);
        this.startDate.setHours(0, 0, 0, 0);
      } else if (hour === this.YEAR) {
        this.startDate.setDate(this.startDate.getDate() - 364);
        this.startDate.setHours(0, 0, 0, 0);
      } else {
        this.startDate = new Date(new Date().getTime() - hour * this.SECONDS_IN_HOUR);
      }
    }
  }

  private isAllCheckBoxSelected(workItem: EventStatusItems[], filterItem: string): void {
    const selectedCount = workItem?.filter(item => item.checked)?.length;
    if (selectedCount === workItem?.length - 1) {
      workItem[0].checked = true;
    } else if (selectedCount === 0) {
      this.isAllCheckBxSelectforAllUncheck(workItem);
    }

    this.getSelectedStatus(workItem, filterItem);
  }

  private getSelectedStatus(workItem: EventStatusItems[], filterItem: string): void {
    if (filterItem === FILTEREVENTS.WORKFLOW) {
      this.selectedWorkFlowId = this.filterSelectedData(workItem, this.selectedWorkFlowId);
    } else if (filterItem === FILTEREVENTS.RISK) {
      this.selectedRiskStatus = this.filterSelectedData(workItem, this.selectedRiskStatus);
    } else if (filterItem === FILTEREVENTS.EVENT) {
      this.selectedEventStatus = this.filterSelectedData(workItem, this.selectedEventStatus);
    } else if (filterItem === FILTEREVENTS.SITE) {
      this.selectedSite = this.filterSelectedData(workItem, this.selectedSite);
    } else if (filterItem === FILTEREVENTS.COUNTRY) {
      this.selectedCountry = this.filterSelectedData(workItem, this.selectedCountry);
    }
  }

  private isAllCheckBxSelectforAllUncheck(workFlowItems: WorkFlowItems[]): void {
    const selectedCount = workFlowItems?.filter(item => item.checked)?.length;
    if (selectedCount === workFlowItems?.length - 1) {
      workFlowItems[0].checked = true;
    }
  }

  private filterSelectedData(items: EventStatusItems[], filterData: string[]): string[] {
    if (items?.length) {
      filterData = items.filter(wf => wf.checked === true).map(wf => wf.value);
    } else {
      filterData = [];
    }
    const checkAll = filterData?.find((swf: string) => swf?.toLowerCase() === WORKFLOWEVENT.ALL) ? true : false;
    if (checkAll) {
      filterData = [];
      filterData.push(WORKFLOWEVENT.ALL);
    }

    return filterData;
  }

  private createFilterData(dataList: WorkFlow[]): EventStatusItems[] {
    if (dataList?.length) {
      const filterArr: EventStatusItems[] = [
        {
          name: 'All',
          value: 'All',
          checked: true,
        },
      ];
      dataList.forEach(list =>
        filterArr.push({
          name: list.name,
          value: list.id,
          checked: true,
        })
      );

      return filterArr;
    }

    return [];
  }

  private setCountryData(country: string[]): EventStatusItems[] {
    if (country?.length) {
      const filterArr: EventStatusItems[] = [
        {
          name: 'All',
          value: 'All',
          checked: true,
        },
      ];
      country.forEach(countryName => {
        filterArr.push({
          name: countryName,
          value: countryName,
          checked: true,
        });
      });

      return filterArr;
    }

    return [];
  }

  private createFilterArray(): { [key: string]: string[] } {
    const data: { [key: string]: string[] } = {
      workFlow: this.selectedWorkFlowId,
      reviewCategory: this.selectedEventStatus,
      riskCategories: this.selectedRiskStatus,
      countryName: this.selectedCountry,
      site: this.selectedSite,
    };

    return data;
  }

  private createFilterSelection(): { [key: string]: EventStatusItems[] } {
    const data: { [key: string]: EventStatusItems[] } = {
      workFlow: this.workFlowItems,
      reviewCategory: this.eventStatusItems,
      riskCategories: this.riskDataList,
      countryName: this.countryList,
      site: this.siteListItem,
    };

    return data;
  }

  private resetLoader(): void {
    this.globalChart.riskLoader = false;
    this.globalChart.alertsLoader = false;
    this.globalChart.summaryLoader = false;
    this.globalEvent.isLoading = false;
  }

  private setRiskDataList(riskData: riskStatus[]): void {
    this.riskDataList = [];
    if (riskData?.length) {
      this.riskDataList.push({
        name: WORKFLOWEVENT.ALL_LABEL,
        value: WORKFLOWEVENT.ALL_LABEL,
        checked: true,
      });
    }
    riskData.forEach((risk: riskStatus) =>
      this.riskDataList.push({
        name: risk.riskCategory.match(/[A-Z][a-z]+/g)?.join(' ') ?? '',
        value: risk.riskCategory.charAt(0).toLowerCase() + risk.riskCategory.substring(1),
        checked: true,
      })
    );
    this.selectedRiskStatus = [WORKFLOWEVENT.ALL];
    this.currentFilterSelection = this.createFilterArray();
    this.disableApply();
  }

  private checkAdmin(countryData: countryList): void {
    this.countryList = this.setCountryData(countryData.country);
    sessionStorage.setItem('isAdmin', countryData.isAdmin.toString());
    this.cameraProfileService.setAdmin(countryData.isAdmin.toString());
    if (!countryData?.isAdmin) {
      this.eventStatusItems = this.eventStatusItems.filter(review => review.name.toLowerCase() !== EXCLUDED);
    }
    this.getSiteData([WORKFLOWEVENT.ALL_LABEL]);
  }

  private setAllCheckboxSelected(workItem: EventStatusItems[], filterItem: string): void {
    workItem.forEach(item => (item.checked = true));
    this.getSelectedStatus(workItem, filterItem);
  }

  private initData(): void {
    if (!sessionStorage.getItem('dateRange') || this.fromClear) {
      this.selectedDateRange = DAYMODE.MONTH;
      this.generateDate(this.selectedDateRange);
    }
    if (sessionStorage.getItem('dateRange') !== DAYMODE.CUSTOM) {
      this.dateRangeCalendar = null;
      this.globalChart.setChartTimeline(this.startDate, this.endDate);
    }
    if (Object.keys(this.selectedFilterOptions).length === 0 || this.fromClear) {
      this.setAllCheckboxSelected(this.eventStatusItems, 'event');
      this.setAllCheckboxSelected(this.workFlowItems, 'workflow');
      this.setAllCheckboxSelected(this.riskDataList, 'risk');
      this.setAllCheckboxSelected(this.siteListItem, 'site');
      this.setAllCheckboxSelected(this.countryList, 'country');
    } else {
      this.setWfCheckBoxSelection(this.selectedFilterOptions);
    }
    this.globalChart.switchDays(this.startDate, this.endDate);
    this.globalEvent.switchDays(this.startDate, this.endDate);
    if (this.fromClear) {
      this.globalChart.workFlowEventStatusChange(this.createDefaultArray());
      this.globalEvent.workFlowEventStatusChange(this.createDefaultArray());
      this.previousFilterSelection = this.createDefaultArray();
    } else {
      this.globalChart.workFlowEventStatusChange(this.createFilterArray());
      this.globalEvent.workFlowEventStatusChange(this.createFilterArray());
      this.previousFilterSelection = this.createFilterArray();
    }
  }

  private disableApply(): void {
    this.disableClear();
    if (this.currentFilterSelection && Object.values(this.currentFilterSelection)?.filter(item => item?.length === 0)?.length >= 1) {
      this.filterValueChange = false;

      return;
    }

    if (this.selectedOption === DAYMODE.CUSTOM && (!this.custStartDate || !this.custEndDate)) {
      this.filterValueChange = false;

      return;
    }

    if (this.endDateError) {
      this.filterValueChange = false;

      return;
    }
    if (this.previousFilterSelection && JSON.stringify(this.currentFilterSelection) !== JSON.stringify(this.previousFilterSelection)) {
      this.filterValueChange = true;
    } else {
      if (this.selectedOption === DAYMODE.CUSTOM && this.custStartDate && this.custEndDate) {
        this.checkCustomSession();
      } else if (this.selectedOption !== DAYMODE.CUSTOM) {
        this.filterValueChange = this.selectedDateRange === this.prevSelection ? false : true;
      }
    }

    if (
      this.selectedOption === DAYMODE.CUSTOM &&
      this.custStartDate &&
      this.custEndDate &&
      this.custStartDate?.getTime() === this.custEndDate?.getTime()
    ) {
      this.filterValueChange = false;
    }
  }

  private disableClear(): void {
    const currentSelection = this.createFilterArray();
    const defaultSelection = this.createDefaultArray();
    if (this.selectedDateRange && this.selectedDateRange !== DAYMODE.MONTH) {
      this.isDefault = false;

      return;
    } else {
      if (this.selectedOption || JSON.stringify(currentSelection) !== JSON.stringify(defaultSelection)) {
        this.isDefault = false;
      } else {
        this.isDefault = true;
      }
    }
  }

  private createDefaultArray(): { [key: string]: string[] } {
    const data: { [key: string]: string[] } = {
      workFlow: [WORKFLOWEVENT.ALL],
      reviewCategory: [WORKFLOWEVENT.ALL],
      riskCategories: [WORKFLOWEVENT.ALL],
      countryName: [WORKFLOWEVENT.ALL],
      site: [WORKFLOWEVENT.ALL],
    };

    return data;
  }

  private checkCustomSession(): void {
    this.getEnableCalendar = true;
    this.formGroup.get('startDate')?.enable();
    this.formGroup.get('endDate')?.enable();
    const startDate = sessionStorage.getItem('customStartDate');
    const custStartDate = startDate ? new Date(startDate) : null;
    const endDate = sessionStorage.getItem('customEndDate');
    const custEndDate = endDate ? new Date(endDate) : null;
    this.filterValueChange =
      this.custEndDate?.toISOString() === custEndDate?.toISOString() && this.custStartDate?.toISOString() === custStartDate?.toISOString()
        ? false
        : true;
  }
}
